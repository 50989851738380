#nprogress .bar {
  z-index: 2031; /* Show loading bar on top of nav header. */
  background: #ffffff;
}

#nprogress .peg {
  box-shadow: 0 0 10px #fff, 0 0 5px #fff;
}
.warehouse_cards_main{
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
}
.warehouse_table_head{
  word-break: unset !important;
  white-space: nowrap !important;
}
@media (max-width: 899px) {
  .warehouse_textfield_main .MuiGrid-root.MuiGrid-item {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  .warehouse_cards_main{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
  .warehouse_textfield_main_container{
    padding: 0px !important;
  }
  .warehouse_table_head{
    word-break: unset !important;
    white-space: nowrap !important;
    min-width: 130px !important;
}
.warehouse_card_inner{
  height: inherit;
}
.warehouse_popup_main{
  overflow: auto;
  width: fit-content;
  min-width: max-content;
}
}